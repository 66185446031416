import React from "react";
import {connect} from "react-redux";
import {Bar, BarChart, ResponsiveContainer} from "recharts";
import {getDomainTrafficAnalyticsTinyGraphData} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";

class DomainAnalyticsMiniGraph extends React.Component {
    state = {
        loading: true,
        data: []
    };

    componentDidMount() {
        this.props.getDomainTrafficAnalyticsTinyGraphData(this.props.domain, (res) => {
            this.setState({ loading: false, data: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        })
    }

    render() {
        if(this.state.loading) {
            return <Loading />;
        }

        return (
            <ResponsiveContainer minWidth={90} minHeight={20} style={{padding: 0}} onClick={() => this.props.openAnalytics()}>
                <BarChart width={80} height={20} data={this.state.data} style={{cursor: 'pointer'}}>
                    <Bar dataKey="visits" fill="#0f2463"/>
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default connect(null, { getDomainTrafficAnalyticsTinyGraphData })(DomainAnalyticsMiniGraph);