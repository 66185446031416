import React from "react";
import {connect} from "react-redux";
import {getDomainTrafficAnalyticsDetail, getDomainTrafficAnalyticsDetailGraph} from "../../actions/domains";
import {Col, DatePicker, Divider, Modal, Row, Table, Tooltip} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import moment from "moment";
import {Area, AreaChart, XAxis, YAxis, Tooltip as ReTooltip} from "recharts";
import countryList from 'react-select-country-list';

class DomainAnalyticsDetailModal extends React.Component {
    state = {
        loadingData: false,
        dataLoaded: false,
        graphData: [],
        dates: [moment(), moment()],
        data: [],
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        columnTitles: {
            'COUNTRIES': 'Countries',
            'BOT_TYPES': 'Bot Types',
            'DEVICES': 'Devices',
            'BROWSERS': 'Browsers',
            'OS': 'Operating Systems',
            'REFERERS': 'Referers',
            'PATHS': 'Paths',
        }
    };

    onClose() {
        this.setState({
            loadingData: false,
            dataLoaded: false,
            graphData: [],
            dates: [moment(), moment()],
            data: [],
            pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        }, () => this.props.close());
    }

    componentDidMount() {
        this.setState({ dates: this.props.dates });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.dataLoaded && !prevProps.show && this.props.show && !this.state.loadingData) {
            this.setState({ dates: this.props.dates }, () => {
                this.loadData();
                this.loadGraphData();
            });
        }
    }

    loadData() {
        this.setState({ loadingData: true });

        this.props.getDomainTrafficAnalyticsDetail(
            this.props.guid,
            this.props.field,
            this.state.dates[0].format('YYYY-MM-DD'),
            this.state.dates[1].format('YYYY-MM-DD')
        , (res) => {
            this.setState({ loadingData: false, dataLoaded: true, data: res.data});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingData: false, dataLoaded: true });
            }
        });
    }

    loadGraphData() {
        this.props.getDomainTrafficAnalyticsDetailGraph(
            this.props.guid,
            this.props.field,
            this.state.dates[0].format('YYYY-MM-DD'),
            this.state.dates[1].format('YYYY-MM-DD')
            , (res) => {
                this.setState({ loadingGraphData: false, graphDataLoaded: true, graphData: res.data});
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loadingGraphData: false, graphDataLoaded: true });
                }
            });
    }

    updateDateRange(dates) {
        this.setState({ dates }, () => {
            this.loadData();
            this.loadGraphData();
        });
    }

    countries = countryList();

    render() {
        if(typeof this.props.field === 'undefined') {
            return <></>;
        }

        const { RangePicker } = DatePicker;

        const columns = [
            { title: this.state.columnTitles[this.props.field], dataIndex: 'key', key: 'key', render: (item) => {
                if(this.props.field === 'COUNTRIES') {
                    if(item === '') {
                        return 'Unknown';
                    }

                    return this.countries.getLabel(item);
                } else if (this.props.field === 'BOT_TYPES' || this.props.field === 'DEVICES') {
                    return normalizeEnum(item);
                }

                if(item.length > 50) {
                    return <Tooltip title={item}>{item.substring(0, 70)}...</Tooltip>;
                }

                return item;
            }},
            { title: 'Visits', dataIndex: 'value', key: 'value', align: 'center', width: '20%' },
        ];

        const disabledDate = (current) => {
            return current && (current > moment().endOf('day') || current < moment().subtract(30, 'days'));
        };

        return (
            <Modal
                title='Details'
                onCancel={() => this.onClose()}
                destroyOnClose={true}
                open={this.props.show}
                footer={null}
                width='900px'
            >
                <Row justify='space-between'>
                    <Col />
                    <Col>
                        <RangePicker
                            ranges={{
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                'Last 14 Days': [moment().subtract(14, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                            }}
                            format="YYYY-MM-DD"
                            defaultValue={this.state.dates}
                            value={this.state.dates}
                            disabledDate={disabledDate}
                            allowClear={false}
                            footer={null}
                            onChange={(dates, dateStrings) => this.updateDateRange(dates, dateStrings)} />
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        {this.props.field !== 'COUNTRIES' ? <AreaChart
                            width={850}
                            height={250}
                            data={this.state.graphData}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <XAxis dataKey="timestamp" />
                            <YAxis />
                            <ReTooltip contentStyle={{textTransform: 'capitalize'}} />
                            <Area type="monotone" dataKey="visits" stroke="#0f2463" fill="#0f2463" />
                        </AreaChart> : <></>}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div style={{overflowX: 'auto'}}>
                            <Table
                                size='small'
                                loading={this.state.loadingData}
                                dataSource={this.state.data}
                                columns={columns}/>
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default connect(null, { getDomainTrafficAnalyticsDetail,
    getDomainTrafficAnalyticsDetailGraph })(DomainAnalyticsDetailModal);